import React, { useEffect, useState } from "react";
import ROUTES, { RenderRoutes } from "../Routes/Routes";
import { Header } from "./components/Header";
import { StyleLoader } from "./components/StyleLoader";
export const WebsiteContext = React.createContext({});

/**
 * The top level screen controlling the app.
 */
export function TopLevelScreen () {
	const [ authors, setAuthors ] = useState(null);
	const [ books, setBooks ] = useState(null);
	const [ mailingLists, setMailingLists ] = useState(null);
	const [ websiteDetails, setWebsiteDetails ] = useState(null);
	const [ welcomePageBlocks, setWelcomePageBlocks ] = useState(null);
	const [ currentId, setCurrentId ] = useState(null);

	useEffect(() => {
		getAuthors();
		getBooks();
		getMailingLists();
		getWebsiteDetails();
		getWelcomePageBlocks();
	}, []);
		
	/**
	 * Gets the authors from a json file.
	 */
	async function getAuthors() {
		const response = await fetch("configuration/authors.json")
			.then((r) => r.json());

		setAuthors(response);
	}

	/**
	 * Gets the books from a json file.
	 */
	async function getBooks() {
		const response = await fetch("configuration/books.json")
			.then((r) => r.json());

		setBooks(response);
	}

	/**
	 * Gets the mailing lists from a json file.
	 */
	async function getMailingLists() {
		const response = await fetch("configuration/mailingLists.json")
			.then((r) => r.json());

		setMailingLists(response);
	}

	/**
	 * Gets the website details from a json file.
	 */
	async function getWebsiteDetails() {
		const response = await fetch("configuration/website.json")
			.then((r) => r.json());

		window.document.title = response.title || response.address;
		setWebsiteDetails(response);
	}

	/**
	 * Gets the welcome page blocks from a json file.
	 */
	async function getWelcomePageBlocks() {
		const response = await fetch("configuration/welcomePageBlocks.json")
			.then((r) => r.json());

		setWelcomePageBlocks(response);
	}

	if (!websiteDetails) {
		return null;
	}

	const {
		backgroundClass,
	} = websiteDetails;

	let backgroundImage = backgroundClass ? require("./Textures/" + backgroundClass + ".png") : undefined;
	backgroundImage = `url(${backgroundImage})`;

	return (
		<div style={{ backgroundImage, minHeight: "100vh", overflow: "hidden", width: "100vw", }}>
			<Header 
				websiteDetails={{
					...websiteDetails,
					authors,
					books,
					mailingLists,
					welcomePageBlocks,
				}} 
			/>
			<main role="main" className="container screen-content">
				<WebsiteContext.Provider value={{
					...websiteDetails,
					authors,
					books,
					currentId,
					mailingLists,
					setCurrentId,
					welcomePageBlocks,
				}}>
					<RenderRoutes
						routes={ROUTES}
					/>
				</WebsiteContext.Provider>
			</main>
			<StyleLoader websiteDetails={websiteDetails} />
		</div>
	);
}
