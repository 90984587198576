import React from "react";

/**
 * Displays a horizontal rule block.
 */
export const HRBlock = () => {
	return (
		<div className="row my-4">
			<div className="col">
				<hr className="border-white" style={{ margin: "auto", width: "25%", }} />
			</div>
		</div>
	);
};
