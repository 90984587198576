import React, { useContext } from "react";
import { WebsiteContext } from "./../../TopLevelScreen";

type Props = {
	backplateClass: string,
	bookId: number,
	title: string,
	text: string,
}

const buttonClass = "btn btn-primary ml-1";
const noOpen = "noopener noreferrer";

/**
 * Displays a book highlight.
 */
export const BookHighlight = (props: Props) => {
	const { backplateClass, bookId, title, text, } = props;

	const websiteDetails = useContext(WebsiteContext) || {};
	const { books, } = websiteDetails;

	let book = null;
	for (const item of books) {
		if (item?.bookId === bookId) {
			book = item;
			break;
		}
	}

	if (!book) {
		return null;
	}

	const {
		amazonUrl,
		appleUrl,
		barnesAndNobleUrl,
		coverImageName,
		description: productDescription,
		googleUrl,
		koboUrl,
		name,
		tagline,
	} = book;
	const coverUrl = coverImageName ? `/images/books/${coverImageName}` : null;

	return (
		<div className={"row mt-3 mb-5 mx-1 py-4 " + backplateClass}>
			<div className="col-md-1" />
			<div 
				className="col-md-4"
				onClick={() => { 
					window.open(amazonUrl, "_blank", noOpen);
				}}
			>
				<img
					alt="Book Cover"
					className="book-highlight-cover"
					src={coverUrl}
				/>
			</div>
			<div className="col-md-6">
				<div className="row">
					<div className="col">
						<h3>{title || name}</h3>
					</div>
				</div>
				{text ? (
					<div className="row">
						<div
							className="col book-description"
						>
							{text}
						</div>
					</div>
				) : (
					<>
						<div className="row mb-1">
							<div className="col">
								<h5>{tagline}</h5>
							</div>
						</div>
						<div className="row">
							<div
								className="col book-description"
							>
								{productDescription}
							</div>
						</div>
					</>
				)}
				<div className="row mt-5">
					<div className="col">
						<span
							className={amazonUrl ? buttonClass : "d-none"}
							onClick={() => { 
								window.open(amazonUrl, "_blank", noOpen);
							}}
							title="Amazon"
						>
							<i
								alt="Amazon Logo"
								className="fab fa-amazon text-white"
								style={{
									fontSize: 24,
									height: 28,
									paddingTop: 2,
									textAlign: "center",
									width: 28,
								}}
							/>
						</span>
						<span
							className={appleUrl ? buttonClass : "d-none"}
							onClick={() => { 
								window.open(appleUrl, "_blank", noOpen);
							}}
							title="Apple"
						>
							<i
								alt="Apple Logo"
								className="fab fa-apple text-white"
								style={{
									fontSize: 24,
									height: 28,
									paddingTop: 2,
									textAlign: "center",
									width: 28,
								}}
							/>
						</span>
						<span
							className={koboUrl ? buttonClass : "d-none"}
							onClick={() => { 
								window.open(koboUrl, "_blank", noOpen);
							}}
							title="Kobo"
						>
							<img
								alt="Kobo Logo"
								className="text-white"
								src="/images/Kobo.svg"
								style={{
									height: 28,
									paddingBottom: 2,
									paddingTop: 2,
									textAlign: "center",
									width: 28,
								}}
							/>
						</span>
						<span
							className={barnesAndNobleUrl ? buttonClass : "d-none"}
							onClick={() => { 
								window.open(barnesAndNobleUrl, "_blank", noOpen);
							}}
							title="Barnes &amp; Noble"
						>
							<img
								alt="Barnes and Noble Logo"
								className="text-white"
								src="/images/BAN.svg"
								style={{
									height: 28,
									paddingBottom: 6,
									paddingTop: 6,
									textAlign: "center",
									width: 28,
								}}
							/>
						</span>
						<span
							className={googleUrl ? buttonClass : "d-none"}
							onClick={() => { 
								window.open(googleUrl, "_blank", noOpen);
							}}
							title="Google Books"
						>
							<i
								alt="Google Logo"
								className="fa-brands fa-google text-white"
								style={{
									fontSize: 18,
									height: 28,
									paddingTop: 5,
									textAlign: "center",
									width: 28,
								}}
							/>
						</span>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col">
						<small
							className="text-muted"
						>
							As an Amazon Associate I earn from qualifying purchases. Buying a product through the links on this site help maintain it.
						</small>
					</div>
				</div>
			</div>
		</div>
	);
};
