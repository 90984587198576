import React from "react";

type Props = {
	text: String,
}

/**
 * Displays a text block.
 */
export const TextBlock = (props: Props) => {
	const { text, } = props;

	return (
		<div className="row mb-4">
			<div
				className="col"
				style={{
					overflowY: "auto",
					whiteSpace: "pre-wrap",
				}}
			>
				{text}
			</div>
		</div>
	);
};
