import React from "react";

type Props = {
	backplateClass: String,
	description: String,
	onNavigate: (newsletterId: Number, url: String) => void,
	imageUrl: String,
	newsletterId: Number,
	title: String,
	url: String,
}

/**
 * Displays a newsletter sign up row.
 */
export const NewsletterRow = (props: Props) => {
	const {
		backplateClass,
		description,
		onNavigate,
		imageUrl,
		newsletterId,
		title,
		url,
	} = props;

	return (
		<div className={"row mb-5 mx-1 py-4 " + backplateClass}>
			<div className={imageUrl ? "col-md-3" : "d-none"}>
				<a
					href={url}
					rel="noopener noreferrer"
					target="_blank"
				>
					<img
						alt="Newsletter"
						className="img-fluid"
						src={imageUrl}
					/>
				</a>
			</div>
			<div className={imageUrl ? "col-md-9" : "col-md-12"}>
				<div className="row">
					<div className="col">
						<h3>{title}</h3>
					</div>
				</div>
				<div className="row">
					<div
						className="col"
						style={{
							overflowY: "auto",
							whiteSpace: "pre-wrap",
						}}
					>
						{description}
					</div>
				</div>
				<div className="row mt-3">
					<div className="col">
						<button
							className="btn btn-primary"
							onClick={() => onNavigate(newsletterId, url)}
						>
							Sign Up
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
