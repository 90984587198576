import React from "react";

/**
 * Displays a spacing block.
 */
export const SpacingBlock = () => {
	return (
		<div className="row mb-2">
			<div className="col">
				&nbsp;
			</div>
		</div>
	);
};
