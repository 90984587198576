import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebsiteContext } from "./../TopLevelScreen";

/**
 * Displays a gallery of the author's books.
 */
export const BookGallery = () => {
	const navigate = useNavigate();
	const [ series, setSeries ] = useState({});
	const [ genres, setGenres ] = useState({});
	const [ selectedGenre, setSelectedGenre ] = useState(null);

	const websiteDetails = useContext(WebsiteContext) || {};
	const { books, setCurrentId, } = websiteDetails;

	useEffect(() => {
		const newSeries = {};
		const newGenres = {};
		for (const book of books) {
			const { genres, series, } = book;

			if (series) {
				if (!newSeries[series]) {
					newSeries[series] = [];
				}

				newSeries[series].push(book);
			} else {
				if (!newSeries.Standalones) {
					newSeries.Standalones = [];
				}

				newSeries.Standalones.push(book);
			}

			if (genres) {
				const genreSplit = genres.split(";");

				for (const genre of genreSplit) {

					if (!newGenres[genre]) {
						newGenres[genre] = [];
					}

					newGenres[genre].push(book);
				}
			}
		}

		setSeries(newSeries);
		setGenres(newGenres);
	}, [ books ]);

	/**
	 * Displays an option for each genre.
	 */
	function eachGenreOption (item, index) {
		return (
			<option key={index} value={item}>{item}</option>
		);
	}

	/**
	 * Displays a row for each series
	 */
	function eachSeries (item, index) {
		const seriesDetails = series[item];

		return (
			<div key={"fragment" + index}>
				<div className="row" key={"headerRow" + index}>
					<div className="col" key={"headerCol" + index}>
						<h4 key={"header" + index}>{item}</h4>
					</div>
				</div>
				<div className="row" key={"detailRow" + index}>
					<div
						className="col-md-12 d-flex flex-wrap justify-content-left mt-2 mb-5"
						key={"detailCol" + index}
					>
						{seriesDetails && seriesDetails.length > 0 && seriesDetails.map((item, index) => {
							return eachBook(item, index);
						})}
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Displays each book the author has written.
	 */
	function eachBook (item, index) {
		const { bookId, coverImageName, name, } = item;

		const coverUrl = "images/books/" + coverImageName;

		return (
			<div 
				className="book-thumbnail-div" 
				key={"book-thumbnail-div" + index}
				onClick={() => {
					console.log(bookId);
					setCurrentId(bookId);
					navigate("/book");
				}}
			>
				<img
					alt={name}
					className="book-thumbnail mx-1"
					key={index}
					
					src={coverUrl}
					title={name}
				/>
			</div>
		);
	}

	const genreBooks = [];
	if (selectedGenre && selectedGenre !== "All") {
		for (const book of books) {
			if (book.genres.includes(selectedGenre)) {
				genreBooks.push(book);
			}
		}
	}

	return (
		<>
			<div className="row mb-3">
				<div className="col-md-3">
					<div className="form-group">
						<h4
							htmlFor="genreSelect"
						>
							Genre
						</h4>
						<select
							className="form-control"
							id="genreSelect"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setSelectedGenre(target.value);
							}}
						>
							<option value="All">All</option>
							{genres && Object.keys(genres) && Object.keys(genres).length > 0 && Object.keys(genres).map((item, index) => {
								return eachGenreOption(item, index);
							})}
						</select>
					</div>
				</div>
			</div>
			{selectedGenre && selectedGenre !== "All" ? (
				<div className="row">
					<div className="col-md-12 d-flex flex-wrap justify-content-left mt-2 mb-5">
						{genreBooks.map((item, index) => {
							return eachBook(item, index);
						})}
					</div>
				</div>
			) : (
				series && Object.keys(series) && Object.keys(series).length > 0 && Object.keys(series).map((item, index) => {
					return eachSeries(item, index);
				})
			)}
		</>
	);
};
