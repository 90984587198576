import React, { useContext } from "react";
import { WebsiteContext } from "./../TopLevelScreen";
import { BookHighlight } from "./components/BookHighlight";
import { HRBlock } from "./components/HRBlock";
import { ImageBlock } from "./components/ImageBlock";
import { SpacingBlock } from "./components/SpacingBlock";
import { TextBlock } from "./components/TextBlock";
import { TitleBlock } from "./components/TitleBlock";

/**
 * Displays a list of genre squares and lets the user select one.
 */
export function WelcomeScreen () {
	const websiteDetails = useContext(WebsiteContext);
	const websiteId = websiteDetails?.websiteId;

	if (!websiteDetails) {
		return (
			<>
				<>
					<div className="jumbotron">
						<h1>Under Construction</h1>
					</div>
				</>
			</>
		);
	}

	const { welcomePageBlocks, } = websiteDetails;
	if (!welcomePageBlocks) {
		return null;
	}

	const sortedBlocks = welcomePageBlocks.sort((a, b) => {
		const aIndex = a.index;
		const bIndex = b.index;

		if (aIndex < bIndex) {
			return -1;
		}

		return 1;
	});

	/**
	 * Displays a welcome page block.
	 */
	function eachWelcomePageBlock (item, index) {
		switch (item.type) {
			case 1: {
				return <TextBlock key={index} {...item} websiteId={websiteId} />;
			}
			case 2: {
				return <TitleBlock key={index} {...item} websiteId={websiteId} />;
			}
			case 3: {
				return <ImageBlock key={index} {...item} websiteId={websiteId} />;
			}
			case 4: {
				return <BookHighlight key={index} {...item} websiteId={websiteId} />;
			}
			case 5: {
				return <HRBlock key={index} {...item} websiteId={websiteId} />;
			}
			case 6: {
				return <SpacingBlock key={index} {...item} websiteId={websiteId} />;
			}
			default: {
				return <TextBlock key={index} {...item} websiteId={websiteId} />;
			}
		}
	}

	return (
		<>
			{sortedBlocks && sortedBlocks.map((block, index) => {
				return eachWelcomePageBlock(block, index);
			})}
		</>
	);
}
