import React, { useContext } from "react";
import { WebsiteContext } from "./../TopLevelScreen";
import { NewsletterRow } from "./components/NewsletterRow";

/**
 * Displays the newsletters for the user.
 */
export const Newsletter = () => {
	const websiteDetails = useContext(WebsiteContext) || {};
	const { newsletters, } = websiteDetails;

	/**
	 * Displays a row for each newsletter.
	 */
	function eachNewsletter (item, index) {
		return (
			<div key={index}>
				<NewsletterRow {...item} onNavigate={(id, url) => {
					window.open(url, "_blank", "noopener noreferrer");
				}} />
				{index + 1 < newsletters.length && (
					<div className="row">
						<hr className="border-white mb-5" style={{ margin: "auto", width: "25%", }} />
					</div>
				)}
			</div>
		);
	}

	return (
		<>
			{newsletters && newsletters.length > 0 && newsletters.map((item, index) => { 
				return eachNewsletter(item, index);
			})}
		</>
	);
};
