import React, { Component } from "react";
import { HashRouter as Router } from "react-router-dom";
import { TopLevelScreen } from "./Screens/TopLevelScreen";
import "./App.scss";

/**
 * The top level of the application.
 */
class App extends Component {
	/**
	 * Renders the components.
	 */
	render () {
		return (
			<Router>
				<TopLevelScreen />
			</Router>
		);
	}
}

export default App;
