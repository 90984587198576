import React, { useContext } from "react";
import { WebsiteContext } from "./../TopLevelScreen";
import { useNavigate } from "react-router-dom";

const buttonClass = "btn btn-primary ml-1 mb-1";
const noOpen = "noopener noreferrer";

/**
 * Displays the information for a book.
 */
export const BookScreen = () => {
	const navigate = useNavigate();
	const websiteDetails = useContext(WebsiteContext) || {};
	const { currentId, books, } = websiteDetails;

	let book = {};
	for (const book_ of books) {
		if (book_.bookId === currentId) {
			book = book_;
			break;
		}
	}

	const {
		amazonUrl,
		appleUrl,
		barnesAndNobleUrl,
		coverImageName,
		description,
		googleUrl,
		koboUrl,
		name,
		tagline,
	} = book;
	const coverUrl = "images/books/" + coverImageName;

	return (
		<>
			<div className="row">
				<div className="col-md-6">
					<img
						alt="Book Cover"
						className="book-cover"
						src={coverUrl}
					/>
				</div>
				<div className="col-md-6">
					<div className="row">
						<div className="col">
							<h3>{name}</h3>
						</div>
					</div>
					<div className="row mb-1">
						<div className="col">
							<h5>{tagline}</h5>
						</div>
					</div>
					<div className="row">
						<div
							className="col product-description"
						>
							{description}
						</div>
					</div>
					<div className="row mt-5">
						<div className="col">
							<span
								className={amazonUrl ? buttonClass : "d-none"}
								onClick={() => { 
									window.open(amazonUrl, "_blank", noOpen);
								}}
								title="Amazon"
							>
								<i
									alt="Amazon Logo"
									className="fab fa-amazon text-white"
									style={{
										fontSize: 44,
										height: 48,
										paddingTop: 2,
										textAlign: "center",
										width: 48,
									}}
								/>
							</span>
							<span
								className={appleUrl ? buttonClass : "d-none"}
								onClick={() => { 
									window.open(appleUrl, "_blank", noOpen);
								}}
								title="Apple"
							>
								<i
									alt="Apple Logo"
									className="fab fa-apple text-white"
									style={{
										fontSize: 44,
										height: 48,
										paddingTop: 2,
										textAlign: "center",
										width: 48,
									}}
								/>
							</span>
							<span
								className={koboUrl ? buttonClass : "d-none"}
								onClick={() => { 
									window.open(koboUrl, "_blank", noOpen);
								}}
								title="Kobo"
							>
								<img
									alt="Kobo Logo"
									className="text-white"
									src="/images/Kobo.svg"
									style={{
										height: 48,
										paddingBottom: 2,
										paddingTop: 2,
										textAlign: "center",
										width: 48,
									}}
								/>
							</span>
							<span
								className={barnesAndNobleUrl ? buttonClass : "d-none"}
								onClick={() => { 
									window.open(barnesAndNobleUrl, "_blank", noOpen);
								}}
								title="Barnes &amp; Noble"
							>
								<img
									alt="Barnes and Noble Logo"
									className="text-white"
									src="/images/BAN.svg"
									style={{
										height: 48,
										paddingBottom: 6,
										paddingTop: 6,
										textAlign: "center",
										width: 48,
									}}
								/>
							</span>
							<span
								className={googleUrl ? buttonClass : "d-none"}
								onClick={() => { 
									window.open(googleUrl, "_blank", noOpen);
								}}
								title="Google Books"
							>
								<i
									alt="Google Logo"
									className="fab fa-google text-white"
									style={{
										fontSize: 38,
										height: 48,
										paddingTop: 5,
										textAlign: "center",
										width: 48,
									}}
								/>
							</span>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col">
							<small
								className="text-muted"
							>
								As an Amazon Associate I earn from qualifying purchases. Buying a product through the links on this site help maintain it.
							</small>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col">
							<button className="btn btn-outline-primary float-right" onClick={() => navigate(-1)}>
								{"< Back"}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
