import React from "react";
import {
	EmailIcon,
	FacebookIcon,
	PinterestIcon,
	RedditIcon,
	TumblrIcon,
	TwitterIcon
} from "react-share";

type Props = {
	aboutTheAuthor: string,
	authorName: string,
	blogLink: string,
	emailAddress: string,
	facebookLink: string,
	imageUrl: string,
	instagramLink: string,
	pinterestLink: string,
	redditLink: string,
	tumblrLink: string,
	twitterLink: string,
	youTubeLink: string,
}

const buttonClass = "btn ml-1 mb-1";

/**
 * Displays an author's information.
 */
export const AuthorBlock = (props: Props) => {
	const {
		aboutTheAuthor,
		authorName,
		blogLink,
		emailAddress,
		facebookLink,
		imageUrl,
		instagramLink,
		pinterestLink,
		redditLink,
		tumblrLink,
		twitterLink,
		youTubeLink,
	} = props;

	return (
		<div className="mb-5">
			<div className="row">
				<div className={imageUrl ? "col-3" : "d-none"}>
					<img alt="Author" className="author-image" src={imageUrl} />
				</div>
				<div className="col">
					<div className="row">
						<div className="col">
							<h2>{authorName}</h2>
						</div>
					</div>
					<div className="row">
						<div
							className="col"
							style={{
								overflowY: "auto",
								whiteSpace: "pre-wrap",
							}}
						>
							{aboutTheAuthor}
						</div>
					</div>
				</div>
			</div>
			<div className="row mt-5">
				<div className={
					emailAddress || facebookLink || instagramLink || pinterestLink || twitterLink || redditLink || tumblrLink || youTubeLink || blogLink
						? "col"
						: "d-none"
				}
				>
					<h4>Contact Me</h4>
				</div>
			</div>
			<div className="row mt-3">
				<div className={emailAddress ? buttonClass : "d-none"}>
					<a
						href={"mailto:" + emailAddress}
						title="Email Me"
					>
						<EmailIcon />
					</a>
				</div>
				<div className={facebookLink ? buttonClass : "d-none"}>
					<a
						href={facebookLink}
						rel="noopener noreferrer"
						target="_blank"
						title="Facebook"
					>
						<FacebookIcon />
					</a>
				</div>
				<div className={instagramLink ? buttonClass : "d-none"}>
					<a
						href={instagramLink}
						rel="noopener noreferrer"
						target="_blank"
						title="Instagram"
					>
						<i
							className="fab fa-instagram"
							style={{
								backgroundColor: "black",
								color: "white",
								fontSize: 64,
								height: 64,
								textAlign: "center",
								width: 64,
							}}
						/>
					</a>
				</div>
				<div className={pinterestLink ? buttonClass : "d-none"}>
					<a
						href={pinterestLink}
						rel="noopener noreferrer"
						target="_blank"
						title="Pinterest"
					>
						<PinterestIcon />
					</a>
				</div>
				<div className={twitterLink ? buttonClass : "d-none"}>
					<a
						href={twitterLink}
						rel="noopener noreferrer"
						target="_blank"
						title="Twitter"
					>
						<TwitterIcon />
					</a>
				</div>
				<div className={redditLink ? buttonClass : "d-none"}>
					<a
						href={redditLink}
						rel="noopener noreferrer"
						target="_blank"
						title="Reddit"
					>
						<RedditIcon />
					</a>
				</div>
				<div className={tumblrLink ? buttonClass : "d-none"}>
					<a
						href={tumblrLink}
						rel="noopener noreferrer"
						target="_blank"
						title="Tumblr"
					>
						<TumblrIcon />
					</a>
				</div>
				<div className={youTubeLink ? buttonClass : "d-none"}>
					<a
						href={youTubeLink}
						rel="noopener noreferrer"
						target="_blank"
						title="YouTube"
					>
						<i
							className="fab fa-youtube-square"
							style={{
								backgroundColor: "red",
								color: "white",
								fontSize: 64,
								height: 64,
								textAlign: "center",
								width: 64,
							}}
						/>
					</a>
				</div>
				<div className={blogLink ? buttonClass : "d-none"}>
					<a
						href={blogLink}
						rel="noopener noreferrer"
						target="_blank"
						title="Blog"
					>
						<i
							className="fas fa-blog"
							style={{
								backgroundColor: "black",
								color: "white",
								fontSize: 32,
								height: 64,
								paddingTop: 16,
								textAlign: "center",
								width: 64,
							}}
						/>
					</a>
				</div>
			</div>
		</div>
	);
};
