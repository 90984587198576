import React from "react";
import { WebsiteDetailsType } from "../../Types";

type Props = {
	websiteDetails: WebsiteDetailsType,
}

/**
 * Loads the stylesheet for the website.
 */
export const StyleLoader = (props: Props) => {
	const { websiteDetails, } = props;
	let theme = websiteDetails?.theme;
	theme = theme ? theme.toLowerCase() : "cosmo";

	const href = `https://bootstrap-styling.s3.eu-north-1.amazonaws.com/bootswatch/${theme}.css`;

	return (
		<link
			href={href}
			rel="stylesheet"
			type="text/css"
		/>
	);
};
