import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebsiteDetailsType } from "../../Types";

type Props = {
	websiteDetails: WebsiteDetailsType,
}

const activeLinkClass = "navbar-item py-1 mr-3 text-white text-decoration-underline";
const inactiveLinkClass = "navbar-item py-1 mr-3 text-white";

/**
 * Displays a header at the top of the site.
 */
export function Header (props: Props) {
	const { websiteDetails, } = props;
	const [ menuVisible, setMenuVisible ] = useState(false);
	const navigate = useNavigate();

	if (!websiteDetails) {
		return null;
	}

	const { 
		address,
		authors,
		blogLink, 
		books,
		newsletters,
		tagline,
		title,
	} = websiteDetails;

	const fullUrl = window.location.href;
	let activeLink = 0;
	if (fullUrl.includes("/book")) {
		activeLink = 1;
	} else if (fullUrl.includes("/newsletter")) {
		activeLink = 2;
	} else if (fullUrl.includes("/about")) {
		activeLink = 3;
	}

	return (
		<div className="fixed-top header-bar">
			<div className="navbar navbar-expand-md navbar-dark bg-primary pb-1">
				<div className="navbar-brand" onClick={() => { navigate("/"); }}>
					<h1
						className="header-title"
					>
						{title || address}
					</h1>
					<h6
						className="header-title"
					>
						{tagline}&nbsp;
					</h6>
				</div>
			</div>
			<div
				className="navbar navbar-expand-md navbar-dark navbar-mover"
			>
				<button
					className="btn btn-primary d-xs-block d-md-none"
					onClick={() => { setMenuVisible(!menuVisible); }}
				>
					<i className="fa fa-bars" />
						&nbsp;Menu
				</button>
				<div
					className={menuVisible ? "navbar-container bg-primary navbar-collapse d-block" : "navbar-container collapse navbar-collapse"}
					id="navvieSupportedContent"
					onClick={() => setMenuVisible(false)}
				>
					<ul className="navbar-nav mr-auto ml-1">
						<li
							className={activeLink === 0 ? activeLinkClass : inactiveLinkClass}
						>
							<span
								onClick={() => navigate("/")}
							>
								Home
							</span>
						</li>
						<li
							className={
								books && books.length > 0
									? activeLink === 1 ? activeLinkClass : inactiveLinkClass
									: "d-none"
							}
						>
							<span
								onClick={() => navigate("/books")}
							>
								Books
							</span>
						</li>
						<li
							className={
								newsletters && newsletters.length > 0
									? activeLink === 2 ? activeLinkClass : inactiveLinkClass
									: "d-none"
							}
						>
							<span
								onClick={() => navigate("/newsletter")}
							>
								Newsletter
							</span>
						</li>
						<li
							className={
								authors && authors.length > 0
									? activeLink === 3 ? activeLinkClass : inactiveLinkClass
									: "d-none"
							}
						>
							<span
								onClick={() => navigate("/about")}
							>
								About the Author{authors && authors.length > 1 && "s"}
							</span>
						</li>
						<li
							className={blogLink ? inactiveLinkClass : "d-none"}
						>
							<span
								onClick={() => window.open(blogLink, "_blank", "noopener noreferrer")}
							>
								Blog
							</span>
						</li>
					</ul>
				</div>
			</div>
			<div
				className={menuVisible ? "header-menu-overlay" : "d-none"}
				onClick={() => setMenuVisible(false)}
			/>
		</div>
	);
}
