import React from "react";

type Props = {
	title: String,
}

/**
 * Displays a title block.
 */
export const TitleBlock = (props: Props) => {
	const { title, } = props;

	return (
		<div className="row mb-2">
			<div className="col">
				<h2>
					{title}
				</h2>
			</div>
		</div>
	);
};
