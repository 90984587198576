import React from "react";
import { Route, Routes } from "react-router-dom";
import { AboutTheAuthor } from "../Screens/AboutTheAuthor/AboutTheAuthor";
import { BookGallery } from "../Screens/BookGallery/BookGallery";
import { BookScreen } from "../Screens/BookScreen/BookScreen";
import { Newsletter } from "../Screens/Newsletter/Newsletter";
import { WelcomeScreen } from "../Screens/WelcomeScreen/WelcomeScreen";

const ROUTES = [
	{
		/**
		 *
		 */
		element: <WelcomeScreen />,
		exact: true,
		key: "Root",
		path: "/",
	},
	{
		/**
		 *
		 */
		element: <BookGallery />,
		exact: true,
		key: "Book Gallery",
		path: "/books",
	},
	{
		/**
		 *
		 */
		element: <Newsletter />,
		exact: true,
		key: "Newsletter",
		path: "/newsletter",
	},
	{
		/**
		 *
		 */
		element: <AboutTheAuthor />,
		exact: true,
		key: "About the Author",
		path: "/about",
	},
	{
		/**
		 *
		 */
		element: <BookScreen />,
		exact: true,
		key: "Book",
		path: "/book",
	}
];

export default ROUTES;

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes ({
	routes,
}: {
	routes: Array<Object>,
}) {
	return (
		<Routes>
			{routes.map((route, i) => {
				return <Route key={route.key} path={route.path} element={route.element} />;
			})}
			<Route component={() => <h1>Not Found!</h1>} />
		</Routes>
	);
}
