import React, { useContext } from "react";
import { WebsiteContext } from "./../TopLevelScreen";
import { AuthorBlock } from "./components/AuthorBlock";

/**
 * Displays the about the author page.
 */
export const AboutTheAuthor = () => {
	const websiteDetails = useContext(WebsiteContext);
	if (!websiteDetails) {
		return null;
	}

	/**
	 * Displays a block for each author.
	 */
	function eachAuthor (item, index) {
		return (
			<AuthorBlock {...item} key={index} />
		);
	}

	const {
		authors,
	} = websiteDetails;

	return (
		<>
			{authors && authors.length > 0 && authors.map((item, index) => {
				return eachAuthor(item, index);
			})}
		</>
	);
};
