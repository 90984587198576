import React from "react";

type Props = {
	imageUrl: string,
	navigationUrl: string,
}

/**
 * Displays an image block.
 */
export const ImageBlock = (props: Props) => {
	const { imageUrl, navigationUrl, } = props;

	/**
	 * Called when the user clicks on a navigation link.
	 */
	function handleNavigate () {
		window.open(navigationUrl, "_blank", "noopener noreferrer");
	}

	return (
		<div className="row mb-4">
			<div className="col">
				{
					navigationUrl ? (
						<span
							onClick={() => { handleNavigate(); }}
						>
							<img
								alt="Welcome"
								src={imageUrl}
								style={{
									maxWidth: "100%",
								}}
							/>
						</span>
					)
						: (
							<img
								alt="Welcome"
								src={imageUrl}
								style={{
									maxWidth: "100%",
								}}
							/>
						)
				}
			</div>
		</div>
	);
};
